<template>
  <div v-if="!empty(series)">
    <div class="h-full w-full rounded border border-gray-800 bg-gray-800/25">
      <BaseLineChart :options="chartOptions" :series="series.series" />
    </div>
  </div>

  <div v-else class="flex w-full flex-col">
    <div class="m-auto">
      <div class="flex h-5 w-full items-center space-x-2">
        <CustomIcons icon="candle-chart" class="h-4 w-4 text-gray-400" />
        <span class="block text-xs">Select Metrics to Visualize</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { formatNumber, formatPrice } from '@/composeables/filters';
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { multiColorSeries } from '@/components/chart/utils/ChartBaseTheming.js';
import { timeframes } from '@/components/alerts/custom/visualization/AlertsCustomVisualizationTimeframes.js';
import { DateTime } from 'luxon';
import CustomIcons from '@/components/CustomIcons.vue';

import colors from 'tailwindcss/colors';
import BaseLineChart from '@/components/chart/BaseLineChart.vue';

const props = defineProps({
  series: { type: Object, required: true },
  alertData: { type: Object, default: null },
  metrics: { type: Array, default: null },
  alertCoins: { type: Array, default: null },
  width: { type: [Number, String], default: 400 },
  height: { type: [Number, String], default: 400 },
  selectedTimeframe: { type: String, default: '6M' }
});

const thresholdLineConfig = color => {
  return {
    background: color,
    color: colors.white['900'],
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: undefined,
    padding: {
      left: 5,
      right: 5,
      top: 0,
      bottom: 2
    }
  };
};

function empty(object) {
  return isEmpty(object);
}

function getYAxisMin(metric) {
  const series_data = props.series.series.filter(e => e.metric_id == metric.id)[0]?.data.map(e => e[1]);
  const metric_values = props.alertData.metrics
    .filter(e => e.id == metric.id && ![null, ''].includes(e.value))
    .map(e => e.value);
  if (!series_data?.length || (!series_data?.length && !metric_values?.length)) return 0;
  const series_min = Math.min(...series_data);
  const metric_min = Math.min(...metric_values);
  const min = !metric_values.length ? series_min : Math.min(metric_min, series_min);
  return min;
}

function getYAxisMax(metric) {
  const series_data = props.series.series.filter(e => e.metric_id == metric.id)[0]?.data.map(e => e[1]);
  const metric_values = props.alertData.metrics
    .filter(e => e.id == metric.id && ![null, ''].includes(e.value))
    .map(e => e.value);
  if (!series_data?.length || (!series_data?.length && !metric_values?.length)) return 0;
  const series_max = Math.max(...series_data);
  const metric_max = Math.max(...metric_values);
  const max = !metric_values.length ? series_max : Math.max(metric_max, series_max);
  return max;
}

const metricsThreshold = (metric, lineColor, i) => {
  return [
    {
      color: lineColor,
      yAxis: i,
      dashStyle: 'ShortDash',
      width: 2,
      value: metric.value,
      label: {
        x: -10,
        y: 0,
        useHTML: true,
        formatter: function () {
          const formatType = metric.type;
          let pointVal =
            formatType?.includes('price') && metric.value < 1 ? formatPrice(metric.value) : formatNumber(metric.value);
          let prefix = formatType?.includes('price') || formatType?.includes('dollar') ? '$' : '';
          let suffix = formatType?.includes('percent') ? '%' : '';
          return `<div><div class="flex border-none space-y-1 py-1 px-2 text-xxs rounded-full align-items" style="background: ${lineColor}"><span class="font-bold pr-1">${
            metric.name
          }</span> ${prefix + pointVal + suffix}</div>`;
        }
      }
    }
  ];
};

const yAxisOptions = computed(() => {
  const metrics = props.metrics;
  if (metrics.length) {
    return metrics.map((metric, i) => {
      const lineColor = multiColorSeries(i);
      const formatType = metric.type;
      let suffix = formatType?.includes('percent')
        ? ' (%)'
        : formatType?.includes('price') || formatType?.includes('dollar')
        ? ' (usd)'
        : '';
      return {
        seriesName: metric.name,
        min: getYAxisMin(metric),
        max: getYAxisMax(metric),
        plotLines: metricsThreshold(metric, lineColor, i),
        opposite: i % 2 != 0,
        title: {
          style: {
            color: lineColor
          },
          text: metric.name + suffix
        },
        labels: {
          formatter: function () {
            return formatType?.includes('price') && this.value < 1 ? formatPrice(this.value) : formatNumber(this.value);
          }
        }
      };
    });
  } else {
    return {
      labels: {
        formatter: function () {
          return formatNumber(this.value);
        }
      }
    };
  }
});

const chartOptions = computed(() => {
  const timeframe = timeframes.find(e => e.id == props.selectedTimeframe);
  const date = new Date();
  return {
    chart: {
      id: 'alerts-create-visualization-chart'
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Date'
      },
      max: DateTime.fromISO(date).ts,
      min: DateTime.fromISO(new Date(date.getTime() - timeframe.time).toISOString()).ts
    },
    yAxis: yAxisOptions.value,
    tooltip: {
      selectedTimeframe: timeframe
    }
  };
});
</script>
